import { LatLngExpression, LatLngTuple } from 'leaflet'
export enum IFieldTypes {
  SELECT = 'select',
  INPUT = 'input',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  PHONE_NUMBER = 'phoneNumber',
  EDITOR = 'editor',
  FEATURES = 'features',
  MAP = 'map',
  BLUE_PLATE = 'bluePlate',
  LANGUAGES = 'languages',
  FILE = 'file',
}

export const enum KAHRAMA_INTERNET {
  EXCLUDED = 1,
  INCLUDED = 2,
}

export const enum USER_TYPE {
  ADMIN = 1,
  BASIC = 2,
  AGENT = 3,
  SUPER_USER = 4,
}

export const TIME_IN_MILLISECONDS = {
  '24_HOURS': 1000 * 60 * 60 * 24,
  '30_MINUTES': 1000 * 60 * 60 * 0.5,
  '800_MILLISECONDS': 800,
}
export const enum DEFAULT_MAP_VALUES {
  LAT = 25.3548,
  LNG = 51.1839,
}

export const DOHA_LAT_LONG: LatLngExpression | LatLngTuple = [
  25.2774898309523, 51.53122272210359,
]

export const enum STYLE_NAME {
  ORIGINAL = 1,
  SMALL_THUMBNAIL = 2,
  MAP_CARD = 3,
  LIST_CARD = 4,
  SIMILAR_CARD = 5,
  DETAIL_GRID = 6,
  DETAIL_COVER = 7,
  DETAIL_GALLERY = 8,
  DETAIL_LARGE = 9,
  DETAIL_MOBILE_GRID = 10,
}

export const STYLE_SIZES = {
  [STYLE_NAME.ORIGINAL]: 0,
  [STYLE_NAME.SMALL_THUMBNAIL]: [160, 120],
  [STYLE_NAME.MAP_CARD]: [180, 113],
  [STYLE_NAME.LIST_CARD]: [450, 282],
  [STYLE_NAME.SIMILAR_CARD]: [350, 242],
  [STYLE_NAME.DETAIL_GRID]: [350, 220],
  [STYLE_NAME.DETAIL_COVER]: [640, 400],
  [STYLE_NAME.DETAIL_GALLERY]: [850, 531],
  [STYLE_NAME.DETAIL_LARGE]: [900, 675],
  [STYLE_NAME.DETAIL_MOBILE_GRID]: [500, 375],
}
export const enum COMMISSION_TYPE_OPTIONS {
  PERCENTAGE = 6,
  CASH = 7,
}
export const enum DOWN_PAYMENT_OPTIONS {
  PERCENTAGE = 1,
  CASH = 2,
}

export interface Action {
  action: string
  icon: any
  label: string
  className: string
}

export interface ModerationComment {
  moderationComment: string
  commentedAt: string | null
}

export enum STATUS {
  DRAFT = -1,
  UNPUBLISHED = 0,
  PUBLISHED = 1,
  SOLD = 2,
  PAYMENT_PENDING = 3,
  REMOVE = 4,
  PENDING_APPROVAL = 5,
  APPROVED = 6,
  REJECTED = 7,
  NEED_CHANGES = 8,
  EXPIRED = 9,
}

export const priceRanges = [
  {
    purposeId: 1, // Rent
    minOptions: [
      { name: '0', id: 0 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
    ],
    maxOptions: [
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    purposeId: 2, // Sale
    minOptions: [
      { name: '0', id: 0 },
      { name: '10,000', id: 10000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: '40,000', id: 40000 },
      { name: '50,000', id: 50000 },
      { name: '60,000', id: 60000 },
      { name: '70,000', id: 70000 },
      { name: '80,000', id: 80000 },
      { name: '90,000', id: 90000 },
      { name: '100,000', id: 100000 },
      { name: '250,000', id: 250000 },
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '10,000', id: 10000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: '40,000', id: 40000 },
      { name: '50,000', id: 50000 },
      { name: '60,000', id: 60000 },
      { name: '70,000', id: 70000 },
      { name: '80,000', id: 80000 },
      { name: '90,000', id: 90000 },
      { name: '100,000', id: 100000 },
      { name: '250,000', id: 250000 },
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 1, // Rent & Residential
    propertyTypeId: [1, 2], // Apartment & Villa
    minOptions: [
      { name: '0', id: 0 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
    ],
    maxOptions: [
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 1, // rent & Residential
    propertyTypeId: [3], // Hotel Stay
    minOptions: [
      { name: '0', id: 0 },
      { name: '500', id: 500 },
      { name: '1,000', id: 1000 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
    ],
    maxOptions: [
      { name: '500', id: 500 },
      { name: '1,000', id: 1000 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 1, // Residential
    propertyTypeId: [4], // Shared
    minOptions: [
      { name: '0', id: 0 },
      { name: '200', id: 200 },
      { name: '400', id: 400 },
      { name: '600', id: 600 },
      { name: '800', id: 800 },
      { name: '1,000', id: 1000 },
    ],
    maxOptions: [
      { name: '200', id: 200 },
      { name: '400', id: 400 },
      { name: '600', id: 600 },
      { name: '800', id: 800 },
      { name: '1000', id: 1000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 1, // Residential
    propertyTypeId: [1], // Apartment
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 1, // Residential
    propertyTypeId: [2], // Villa
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 1, // Residential
    propertyTypeId: [16], // Full Building
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
    ],
    maxOptions: [
      { name: '1,500,000', id: 1500000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '5,000,000', id: 5000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 2, // commercial
    propertyTypeId: [5], // retail
    minOptions: [
      { name: '0', id: 0 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
    ],
    maxOptions: [
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 2, // commercial
    propertyTypeId: [6], // warehouse
    minOptions: [
      { name: '0', id: 0 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
    ],
    maxOptions: [
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 2, // commercial
    propertyTypeId: [7], // labour camp
    minOptions: [
      { name: '0', id: 0 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
    ],
    maxOptions: [
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 2, // commercial
    propertyTypeId: [8], // land
    minOptions: [
      { name: '0', id: 0 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
    ],
    maxOptions: [
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 2, // commercial
    propertyTypeId: [9], // storage
    minOptions: [
      { name: '0', id: 0 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
    ],
    maxOptions: [
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 2, // commercial
    propertyTypeId: [10], // Building / Compound
    minOptions: [
      { name: '0', id: 0 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
    ],
    maxOptions: [
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 2, // commercial
    propertyTypeId: [11], // Office
    minOptions: [
      { name: '0', id: 0 },
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
    ],
    maxOptions: [
      { name: '2,000', id: 2000 },
      { name: '3,000', id: 3000 },
      { name: '4,000', id: 4000 },
      { name: '5,000', id: 5000 },
      { name: '6,000', id: 6000 },
      { name: '7,000', id: 7000 },
      { name: '8,000', id: 8000 },
      { name: '9,000', id: 9000 },
      { name: '10,000', id: 10000 },
      { name: '15,000', id: 15000 },
      { name: '20,000', id: 20000 },
      { name: '30,000', id: 30000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 3, // sale & residential
    propertyTypeId: [14, 15], // Apartment, Villa
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 3, // sale & residential
    propertyTypeId: [16], // Full Building
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '5,000,000', id: 5000000 },
    ],
    maxOptions: [
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '5,000,000', id: 5000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 4, // sale & commercial
    propertyTypeId: [17], // Office space
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 4, // sale & commercial
    propertyTypeId: [18], // retail
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 4, // sale & commercial
    propertyTypeId: [19], // warehouse
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 4, // sale & commercial
    propertyTypeId: [20], // Labour Camp
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 4, // sale & commercial
    propertyTypeId: [21], // land
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 4, // sale & commercial
    propertyTypeId: [22], // Business
    minOptions: [
      { name: '0', id: 0 },
      { name: '100,000', id: 100000 },
      { name: '200,000', id: 200000 },
      { name: '300,000', id: 300000 },
      { name: '400,000', id: 400000 },
      { name: '500,000', id: 500000 },
    ],
    maxOptions: [
      { name: '100,000', id: 100000 },
      { name: '200,000', id: 200000 },
      { name: '300,000', id: 300000 },
      { name: '400,000', id: 400000 },
      { name: '500,000', id: 500000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 4, // sale & commercial
    propertyTypeId: [23], // Building / Compound
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '5,000,000', id: 5000000 },
    ],
    maxOptions: [
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '5,000,000', id: 5000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 4, // sale & commercial
    propertyTypeId: [24], // Commercial Space
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 5, // sale & international
    propertyTypeId: [25], // Apartment
    minOptions: [
      { name: '100,000', id: 100000 },
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 5, // sale & international
    propertyTypeId: [26], // Commercial Space
    minOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 5, // sale & international
    propertyTypeId: [27], // House
    minOptions: [
      { name: '100,000', id: 100000 },
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
  {
    categoryId: 5, // sale & international
    propertyTypeId: [28], // Land
    minOptions: [
      { name: '100,000', id: 100000 },
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
    ],
    maxOptions: [
      { name: '500,000', id: 500000 },
      { name: '750,000', id: 750000 },
      { name: '1,000,000', id: 1000000 },
      { name: '1,500,000', id: 1500000 },
      { name: '1,750,000', id: 1750000 },
      { name: '2,000,000', id: 2000000 },
      { name: '2,500,000', id: 2500000 },
      { name: '3,000,000', id: 3000000 },
      { name: '4,000,000', id: 4000000 },
      { name: '5,000,000', id: 5000000 },
      { name: '10,000,000', id: 10000000 },
      { name: 'Any', id: 'any' },
    ],
  },
]

export interface PageParams {
  curPage: number
  perPage: number
  keyword: string | null
  status?: number
  sortBy?: string
  startDate1?: string
  startDate2?: string
  endDate1?: string
  endDate2?: string
  purposeId?: number
  categoryId?: number
  propertyTypeId?: number
  areas?: number
  agentUserIds?: number
  priceMin?: number
  priceMax?: number
}
export interface PageColumn {
  key: string
  label: string
  sorter: boolean
  _style?: { width?: string; minWidth?: string }
  sorterKey?: string
}

export const AGENT_STATUS_LABELS: Record<number, string> = {
  0: 'INVITED',
  1: 'ACCEPTED',
  2: 'ON HOLD',
  3: 'DELETED',
}
