import React, { createContext, useContext, useState, useRef } from 'react'
import { CToast, CToastBody, CToastHeader, CToaster } from '@coreui/react-pro'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ToastContextType = {
  showToast: (msg: string, isError?: boolean) => void
  hideToast: () => void
}

const ToastContext = createContext<ToastContextType | undefined>(undefined)

export const useToast = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toast, setToast] = useState<React.ReactNode | null>(null)
  const toasterRef = useRef<any>(null)

  const showToast = (msg: string, isError = false) => {
    const newToast = (
      <CToast
        color={isError ? 'danger' : 'dark'}
        className="text-white"
        autohide={true}
        visible={true}
        onClose={() => hideToast()}
      >
        <CToastHeader closeButton>
          <FontAwesomeIcon
            icon={isError ? faCircleXmark : faCircleCheck}
            color={isError ? 'red' : 'green'}
          />
          <div className="fw-bold me-auto text-green mx-2">
            {isError ? 'Failed' : 'Success'}
          </div>
        </CToastHeader>
        <CToastBody>{msg}</CToastBody>
      </CToast>
    )

    setToast(newToast)
  }

  const hideToast = () => {
    setToast(null)
  }

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      <CToaster className="p-3" placement="top-end" ref={toasterRef}>
        {toast}
      </CToaster>
    </ToastContext.Provider>
  )
}
