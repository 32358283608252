import { UseControllerProps } from 'react-hook-form'

export const enum AD_MODE {
  // eslint-disable-next-line no-unused-vars
  CREATE = 'create',
  // eslint-disable-next-line no-unused-vars
  EDIT = 'edit',
}
export const enum FILE_TYPE {
  // eslint-disable-next-line no-unused-vars
  IMAGE = 1,
  // eslint-disable-next-line no-unused-vars
  FILE = 2,
}

export const enum LABEL_SUFFIX {
  // eslint-disable-next-line no-unused-vars
  REQUIRED_FIELD = '*',
}
export const IMAGE_ORDERS = ['front_view', 'second', 'third', 'fourth', 'fifth']

// enum for property schema file
export const PROPERTY = {
  PURPOSE: {
    FOR_RENT: 1,
    FOR_SALE: 2,
  },
  CATEGORY: {
    FOR_RENT: {
      RESIDENTIAL: 1,
      COMMERCIAL: 2,
    },
    FOR_SALE: {
      RESIDENTIAL: 3,
      COMMERCIAL: 4,
      INTERNATIONAL: 5,
    },
  },
  TYPE: {
    FOR_RENT: {
      [1]: {
        // Residential
        APARTMENT: 1,
        VILLA: 2,
        HOTEL_STAY: 3,
        SHARED: 4,
      },
      [2]: {
        // Commercial
        RETAIL: 5,
        WAREHOUSE: 6,
        LABOUR_CAMP: 7,
        LAND: 8,
        STORAGE: 9,
        BUILDING_COMPOUND: 10,
        OFFICE: 11,
        COMMERCIAL_SPACE: 12,
      },
    },
    FOR_SALE: {
      [3]: {
        // Residential
        APARTMENT: 14,
        VILLA: 15,
        FULL_BUILDING: 16,
      },
      [4]: {
        // Commercial
        OFFICE_SPACE: 17,
        RETAIL: 18,
        WAREHOUSE: 19,
        LABOUR_CAMP: 20,
        LAND: 21,
        BUSINESS: 22,
        BUILDING_COMPOUND: 23,
        COMMERCIAL_SPACE: 24,
      },
      [5]: {
        // International
        APARTMENT: 25,
        COMMERCIAL_SPACE: 26,
        HOUSE: 27,
        LAND: 28,
      },
    },
  },
}

export const MAX_FILE_SIZE = 30 * 1024 * 1024 // 10MB in bytes
export const ALLOWED_TYPES = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/webp',
  'image/heif',
  'image/heic',
  'application/octet-stream',
] // Add other allowed types if needed

export const RULES_FOR_IMAGES: UseControllerProps['rules'] = {
  required: 'Images is required',
  validate: (value) => {
    if (!value || value.length < 1) {
      return 'Images is required'
    }
    if (value.length < 5) {
      return 'At least 5 images are required'
    }
    if (value.length > 25) {
      return 'Maximum 25 images can be uploaded'
    }
    if (!value[0].viewpoint || value[0].viewpoint !== 'front_view') {
      return 'Cover Photo is required'
    }
    for (const file of value) {
      if (!ALLOWED_TYPES.includes(file.type)) {
        return 'Only .jpeg, .png, .jpg, .heic, .heif, and .webp are supported'
      }
      if (file.size > MAX_FILE_SIZE) {
        return 'The maximum size of file should be 30 mb'
      }
    }
    return true
  },
}

export const DETAIL_DESCRIPTION_WORDS_TO_SHOW = 70
export const MINIMUM_IMAGES_REQUIRED = 5
export const VIEW_GALLERY_IMAGE_INDEX = 4
export const MAX_FILE_LIMIT = 25
export const MAP_DEFAULT_ZOOM = 19

export const PROPERTIES_EMAIL = 'properties@qatarliving.com'
export enum AD_UPLOAD_OPTIONS {
  SINGLE_AD = 'single',
  MULTIPLE_AD = 'multiple',
}

export const KAHRAMA_INTERNET_OPTIONS = [
  {
    name: 'Excluded',
    id: 1,
  },
  {
    name: 'Included',
    id: 2,
  },
]

export enum FIELD_DATA_KEY {
  REAL_ESTATE = 'realEstateType',
  APARTMENT_TYPE = 'apartmentType',
  FITTINGS = 'fitting',
  FURNISHING = 'furnishing',
  BEDROOM = 'bedroom',
  BATHROOM = 'bathroom',
  SQUARE_METERS = 'squareMeters',
  KITCHEN = 'kitchen',
  PARKING = 'parking',
  AC_TYPE = 'acType',
  HAS_KAHRAMA = 'hasKahramaa',
  HAS_INTERNET = 'hasInternet',
  GENDER = 'gender',
  RENTAL_PERIOD = 'rentalPeriod',
  DEPOSIT = 'deposit',
  COMMISSION = 'commission',
  COMMISSION_TYPE = 'commissionType',
  COMMISSION_VALUE = 'commissionValue',
  DOWN_PAYMENT = 'downpayment',
  DOWN_PAYMENT_VALUE = 'downpaymentValue',
  PRICE_FREQUENCY = 'priceFrequency',
  PRICE = 'price',
  PRICE_MIN = 'priceMin',
  PRICE_MAX = 'priceMax',
  FREE_MONTH = 'freeMonth',
  TITLE = 'title',
  DESCRIPTION = 'description',
  FEATURE = 'feature',
  CONTACT_MOBILE = 'contactMobile',
  CONTACT_WHATSAPP = 'contactWhatsapp',
  CONTACT_EMAIL = 'contactEmail',
  REFERENCE_NUMBER = 'referenceNumber',
  AGENT = 'userId',
  SHOW_EXACT_LOCATION = 'showExactLocation',
  BLUE_PLATE = 'bluePlate',
  ZONE = 'zone',
  STREET = 'street',
  BUILDING = 'building',
  LOCATION = 'location',
  PROPERTIES_MAP = 'propertiesMap',
  LANGUAGE = 'language',
  NATIONALITY = 'nationality',
  CITY = 'city',
  COUNTRY = 'country',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PROFILE_PICTURE = 'profilePicture',
  USER_NAME = 'userName',
  MESSAGE = 'message',
  USERNAME_EMAIL_PHONE = 'username_email_phone',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  DESIGNATION = 'designation',
  LICENSE_NUMBER = 'licenseNumber',
  LICENSE_FILE = 'licenseFile',
  AGENCY_NAME = 'agencyName',
  COMPANY_EMAIL = 'companyEmail',
  CR_NUMBER = 'crNumber',
  UPLOAD_CR_ATTACHMENT = 'uploadCRAttachment',
  ABOUT_AGENCY = 'aboutAgency',
  ADDRESS = 'Address',
  AGENT_NAME = 'agentName',
  SHORT_BIO = 'bio',
  AGENCY_LOGO = 'agencyLogoUri',
  VIDEO_LINK = 'videoLink',
  VIRTUAL_TOUR_LINK = 'virtualTourLink',
}
export enum FIELD_NAMES {
  REAL_ESTATE = 'realEstateTypeId',
  APARTMENT_TYPE = 'apartmentTypeId',
  FITTINGS = 'fittingsId',
  FURNISHING = 'furnishingId',
  BEDROOM = 'bedroomId',
  BATHROOM = 'bathroomId',
  SQUARE_METERS = 'squareMeters',
  KITCHEN = 'kitchenId',
  PARKING = 'parkingId',
  AC_TYPE = 'acTypeId',
  HAS_KAHRAMA = 'hasKahramaa',
  HAS_INTERNET = 'hasInternet',
  GENDER = 'genderId',
  RENTAL_PERIOD = 'rentalPeriodId',
  DEPOSIT = 'depositId',
  COMMISSION = 'commissionId',
  COMMISSION_TYPE = 'commissionType',
  COMMISSION_PERCENTAGE = 'commissionPercentage',
  COMMISSION_CASH = 'commissionCash',
  DOWN_PAYMENT = 'downpaymentId',
  DOWN_PAYMENT_PERCENTAGE = 'downpaymentPercentage',
  DOWN_PAYMENT_CASH = 'downpaymentCash',
  PRICE_FREQUENCY = 'priceFrequencyId',
  PRICE = 'price',
  FREE_MONTH = 'freeMonthId',
  TITLE = 'title',
  DESCRIPTION = 'description',
  FEATURE = 'features',
  CONTACT_MOBILE = 'contactMobile',
  CONTACT_WHATSAPP = 'contactWhatsapp',
  CONTACT_EMAIL = 'contactEmail',
  REFERENCE_NUMBER = 'referenceNumber',
  AGENT = 'userId',
  ZONE = 'zoneId',
  STREET = 'street',
  BUILDING = 'building',
  LOCATION = 'locationId',
  SHOW_APPROXIMATE_LOCATION = 'approximateLocation',
  LANGUAGE = 'language',
  NATIONALITY = 'nationality',
  CITY = 'cityId',
  COUNTRY = 'countryId',
  DESIGNATION = 'designation',
  USER_NAME = 'userName',
  LICENSE_NUMBER = 'licenseNumber',
  LICENSE_FILE = 'licenseUri',
  PROFILE_PICTURE = 'imageUri',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MESSAGE = 'message',
  USERNAME_EMAIL_PHONE = 'username_email_phone',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  USERNAME = 'userName',
  AGENCY_NAME = 'agencyName',
  COMPANY_EMAIL = 'agencyEmail',
  CR_NUMBER = 'crNumber',
  UPLOAD_CR_ATTACHMENT = 'crUri',
  ABOUT_AGENCY = 'aboutAgency',
  ADDRESS = 'address',
  AGENT_NAME = 'agentName',
  SHORT_BIO = 'bio',
  AGENT_EMAIL = 'agentEmail',
  AGENCY_LOGO = 'agencyLogoUri',
  VIDEO_LINK = 'videoLink',
  VIRTUAL_TOUR_LINK = 'virtualTourLink',
}

export const LICENSE_FILE_ALLOWED_TYPES = [
  'image/jpg',
  'image/png',
  'application/pdf',
]
export const PROFILE_PICTURE_ALLOWED_TYPES = [
  'image/jpg',
  'image/png',
  'image/jpeg',
  'image/webp',
]

export const LICENSE_FILE_MAX_FILE_SIZE = 500 * 1024 * 1024 // 500MB in bytes
